<template>
  <div v-if="hasRating" :class="[{'flex w-auto text-sm text-neutral-800 font-semibold items-center' : ratingText}]">
    <div v-show="ratingText" class="mr-1.5">
      <UiLanguagePlaceholder v-if="props.rating < 1" domain="SharedRaiting" name="raiting_0to1" />
      <UiLanguagePlaceholder v-if="props.rating === 1 || props.rating > 1 && props.rating < 2 " domain="SharedRaiting" name="raiting_1to2" />
      <UiLanguagePlaceholder v-if="props.rating === 2 || props.rating > 2 && props.rating < 3 " domain="SharedRaiting" name="raiting_2to3" />
      <UiLanguagePlaceholder v-if="props.rating === 3 || props.rating > 3 && props.rating < 4 " domain="SharedRaiting" name="raiting_3to4" />
      <UiLanguagePlaceholder v-if="props.rating === 4 || props.rating > 4 " domain="SharedRaiting" name="raiting_4to5" />
    </div>
    <template v-if="svg">
      <UiIcon
        v-for="(star, index) in stars"
        :key="index"
        :src="`/svg/icons/star-${star}.svg`"
        :width="props.width"
        :height="props.height"
        class="inline-block"
        :class="[$attrs.class]"
      />
    </template>
    <template v-if="!svg">
      <span
        v-for="(star, index) in stars"
        :key="index"
        class="inline-block bg-contain"
        :class="[`star-${star}`, $attrs.class]"
        :style="{ '--star-height': props.height + 'px', '--star-width': props.width + 'px'}"
        title="Star"
      />
    </template>
  </div>
</template>

<script setup>
function getStar (value, halfFrom, fullFrom) {
  if (value >= fullFrom) {
    return 'full'
  } else if (value >= halfFrom) {
    return 'half'
  } else {
    return 'empty'
  }
}

const props = defineProps({
  rating: {
    type: [Number, String],
    default: 5
  },
  width: {
    type: [String, Number],
    default: '20'
  },
  height: {
    type: [String, Number],
    default: '20'
  },
  ratingText: {
    type: Boolean,
    default: false
  },
  svg: {
    type: Boolean,
    default: true
  }
})

defineOptions({
  inheritAttrs: false
})

const stars = computed(function () {
  return [
    getStar(parseFloat(props.rating), 0.0, 1.0),
    getStar(parseFloat(props.rating), 1.1, 1.6),
    getStar(parseFloat(props.rating), 2.1, 2.6),
    getStar(parseFloat(props.rating), 3.1, 3.6),
    getStar(parseFloat(props.rating), 4.1, 4.6)
  ]
})

const hasRating = computed(() => parseFloat(props.rating) > 0)
</script>

<style lang="scss" scoped>

.star-full {
  @apply h-[var(--star-height)] w-[var(--star-width)] bg-[url('/svg/icons/star-full.svg')];
}
.star-empty {
  @apply h-[var(--star-height)] w-[var(--star-width)]  bg-[url('/svg/icons/star-empty.svg')];
}
.star-half {
  @apply h-[var(--star-height)] w-[var(--star-width)]  bg-[url('/svg/icons/star-half.svg')];
}
</style>
